import type { FirebaseOptions } from "firebase/app";

const config: FirebaseOptions = {
  apiKey: "AIzaSyCoSzxdcI-TG-C-bixo_v5gukjnixcB8x8",
  authDomain: "thanku-production.firebaseapp.com",
  databaseURL: "https://thanku-production.firebaseio.com",
  projectId: "thanku-production",
  storageBucket: "thanku-production.appspot.com",
  messagingSenderId: "944491857036",
  appId: "1:944491857036:web:e86e03a77c91a1a7a927a9",
  measurementId: "G-PJTEMQ77NR",
};

export default config;
