import type { FirebaseOptions } from "firebase/app";

const config: FirebaseOptions = {
  apiKey: "AIzaSyAaGaMwp1pEgk2gPpPnULCKgoK8_QskMt4",
  authDomain: "thanku-development.firebaseapp.com",
  databaseURL: "https://thanku-development.firebaseio.com",
  projectId: "thanku-development",
  storageBucket: "thanku-development.appspot.com",
  messagingSenderId: "186050021400",
  appId: "1:186050021400:web:9dd2fc40954817cb91db6d",
  measurementId: "G-2PDB58FNFN",
};

export default config;
