import type { FirebaseOptions } from "firebase/app";
import firebaseConfigDevelopment from "./firebase.config.development";
import firebaseConfigProduction from "./firebase.config.production";

export const appEnv = process.env.APP_ENV ?? "development";
export const appVersion = process.env.APP_VERSION;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseOptions: FirebaseOptions =
  appEnv === "production"
    ? firebaseConfigProduction
    : firebaseConfigDevelopment;
